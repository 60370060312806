import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 1rem;
  background: var(--tableGrey);
  height: 100vh;
`

export const ImageContainer = styled.div`
  display: flex;
  background-color: var(--tableGrey);
  height: 30vh;
  padding: 0 20px;
  align-items: center;
  justify-content: center;

  @media (max-width: 1600px) {
    height: 24vh;
  }
`

export const Contents = styled.div`
  display: flex;
  background-color: var(--tableGrey);
  height: 70vh;
  padding: 0 20px;
  align-items: baseline;
  justify-content: center;
  img {
    height: 138px;
    width: 200px;
  }
`

export const FormContainer = styled.div`
  display: flex;

  z-index: 5;

  background: var(--shape);
  border-radius: 10px;
  font-size: 32px;

  h2 {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: var(--red);

    padding-top: 74px;
    padding: 74 33px;
  }

  h3 {
    font-size: 16px;
    font-weight: 400;
    color: var(--text-body);

    margin-top: 54px;
    margin-bottom: 50px;
  }

  a {
    font-size: 10px;
    text-align: right;
    margin-top: 5px;
    text-decoration: none;
    color: var(--text-body);
  }
`
export const Content = styled.div``

export const StyledFormWrapper = styled.form`
  display: grid;
  justify-content: space-between;
  padding: 0 60px;

  label {
    color: var(--red);
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
  }
`

export const StyledForm = styled.form``

export const StyledInput = styled.input`
  display: block;
  width: 100%;
  background-color: var(--shape);
  border-radius: 8px;
  border: 1px solid;
  height: 32px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 10px;
`

export const StyledInputPassword = styled.div`
  height: 32px;
  width: 350px;

  background: #ffffff;
  border-radius: 8px;

  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  input {
    display: block;
    padding: 20px;
    height: 100%;
    width: 100%;
    border: none;
    border: 1px solid;
    border-radius: 8px;
  }

  img {
    position: absolute;
    width: 25px;
    height: 25px;
    margin-left: 16%;
    cursor: pointer;
  }
`

export const StyledTextArea = styled.textarea``

export const StyledButton = styled.button`
  height: 70px;
  margin-bottom: 30px;
  margin-top: 40px;
  border-radius: 50px;
  background-color: var(--red);
  border: none;
  color: var(--shape);
  font-size: 24px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledFieldset = styled.fieldset``

export const StyledError = styled.div``
