import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { Header } from '../../components/header'
import { SideBar } from '../../components/sideBar'
import {
  Container,
  EmpresasHeader,
  ListBtn,
  ProfileForm,
  StyledEditInput,
  StyledEditSelect,
  StyledFormWrapper,
  ErrorMessage,
} from './styles'
import { api } from '../../services/api'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { Spinner } from '../../components/Spinner'

type FormData = {
  nome: string
  email: string
  admin: boolean
  ativo: boolean
  password: string
  confirm_password: string
}

const createSocioFormSchema = yup.object().shape({
  nome: yup.string().required('Nome Obrigatório'),
  email: yup.string().required('Email Obrigatório').email('Formato invalido'),
  admin: yup.boolean().required('cargo Obrigatório'),
  ativo: yup.boolean().required('Campo Obrigatório'),
  password: yup.string().required('Password é obrigatório'),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords não são iguais'),
})

export function CreateUser() {
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(createSocioFormSchema),
  })

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const createUser = handleSubmit((data) => {
    setLoading(true)
    api
      .post(`/auth/sign-up`, data)
      .then(() => {
        setLoading(false)
        navigate('/configurations/users')
      })
      .catch((error) => {
        console.error(error.response.data.message)
        setError(error.response.data.message)
      })
  })

  return (
    <>
      <Header />
      <SideBar />
      <Container>
        <EmpresasHeader>
          <span>CRIAR NOVO UTILIZADOR</span>
        </EmpresasHeader>

        <form onSubmit={createUser}>
          <ProfileForm>
            <StyledFormWrapper>
              <label>NOME</label>
              <StyledEditInput type="nome" {...register('nome')} />
            </StyledFormWrapper>

            <StyledFormWrapper>
              <label>EMAIL</label>
              <StyledEditInput type="email" {...register('email')} />
            </StyledFormWrapper>

            <StyledFormWrapper>
              <label>Cargo</label>
              <StyledEditSelect {...register('admin')}>
                <option value={'true'}>Administrador</option>
                <option value={'false'}>Utilizador</option>
              </StyledEditSelect>
            </StyledFormWrapper>
          </ProfileForm>

          <ProfileForm>
            <StyledFormWrapper>
              <label>Ativo</label>
              <StyledEditSelect {...register('ativo')}>
                <option value={'true'}>Sim</option>
                <option value={'false'}>Não</option>
              </StyledEditSelect>
            </StyledFormWrapper>

            <StyledFormWrapper>
              <label>NOVA SENHA</label>
              <StyledEditInput type="password" {...register('password')} />
            </StyledFormWrapper>

            <StyledFormWrapper>
              <label>CONFIRMAR NOVA SENHA</label>
              <StyledEditInput
                type="password"
                {...register('confirm_password')}
              />
              {errors.confirm_password && (
                <ErrorMessage>Password não estão iguais</ErrorMessage>
              )}
            </StyledFormWrapper>
          </ProfileForm>

          {!loading && <ListBtn type="submit">CRIAR UTILIZADOR</ListBtn>}

          {!error ? (
            loading && <Spinner stroke="red" />
          ) : (
            <ErrorMessage>{error}</ErrorMessage>
          )}
        </form>
      </Container>
    </>
  )
}
