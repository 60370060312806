import {
  FormContainer,
  StyledFormWrapper,
  StyledInput,
  StyledButton,
  Content,
  Contents,
  Container,
} from './styles'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import logo from '../assets/svg/logoCA.svg'
import { api } from '../../services/api'

type FormData = {
  id: null | undefined
  email: string
}

const postEmailFormSchema = yup.object().shape({
  email: yup.string().required('Email Obrigatório').email('Formato invalido'),
})

export function Recuperar() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    resolver: yupResolver(postEmailFormSchema),
  })

  // Post function Email
  const reporEmail = handleSubmit((data) =>
    api
      .post('/auth/SendMail', data)
      .then(() => {
        reset()
      })
      .catch(() => {
        console.error('DEU ERRADO')
      }),
  )

  return (
    <>
      <Container>
        <a href="/">
          <img src={logo} />
        </a>
      </Container>
      <Contents>
        <FormContainer>
          <Content>
            <StyledFormWrapper onSubmit={reporEmail}>
              <h2>Recuperação de Senha</h2>

              <h3>Insira o seu email abaixo</h3>
              <label>EMAIL</label>
              <StyledInput
                type="email"
                {...register('email')}
                placeholder="Inserir Email"
              />
              {errors.email && <p>Formato do email errado</p>}
              <a>
                Será enviado um email, para efetuar a reposição da sua senha
              </a>
              <StyledButton>Recuperar Senha</StyledButton>
            </StyledFormWrapper>
          </Content>
        </FormContainer>
      </Contents>
    </>
  )
}
