import {
  BtdPaginationUnactive,
  BtnPaginationActive,
  BtnPaginationImg,
  Container,
  UL,
} from './styles'

import left from '../../components/assets/svg/left.svg'
import right from '../../components/assets/svg/right.svg'

type Pagination = {
  limit: number
  total: number
  offset: number
  setOffset: (e: number) => void
}

const MAX_ITEMS = 10 // máximo dos máximos amostra sempre 10 botoes caso passe de 10 paginas ate la amosta os necessários

const Pagination = ({ limit, total, offset, setOffset }: Pagination) => {
  const current = offset ?? 0
  const pages = Math.ceil(total / limit)
  const first = Math.max(current - limit, 1)

  function onPageChange(page: number) {
    setOffset(page)
  }

  return (
    <Container>
      <UL>
        <li>
          <BtnPaginationImg
            onClick={() => onPageChange(current - 1)}
            disabled={current === 1}
          >
            <img src={left} />
          </BtnPaginationImg>
        </li>

        {Array.from({ length: Math.min(MAX_ITEMS, pages) })
          .map((_, index) => index + first)
          .map((page) => (
            <li key={page}>
              {page === current ? (
                <BtnPaginationActive onClick={() => onPageChange(page)}>
                  {page}
                </BtnPaginationActive>
              ) : (
                <BtdPaginationUnactive onClick={() => onPageChange(page)}>
                  {page}
                </BtdPaginationUnactive>
              )}
            </li>
          ))}

        <li>
          <BtnPaginationImg
            onClick={() => onPageChange(current + 1)}
            disabled={current === pages}
          >
            <img src={right} />
          </BtnPaginationImg>
        </li>
      </UL>
    </Container>
  )
}

export default Pagination
