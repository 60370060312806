import { Header } from '../components/header'
import { SideBar } from '../components/sideBar'
import { Container, ListBtn, SimulacoesHeader, Table } from './styles'
import { SearchBody } from '../components/SearchBar/styles'
import { Spinner } from '../components/Spinner'

import { api, mountHeaders } from '../services/api'
import Pagination from '../components/Pagination'

import { FormEvent, useCallback, useEffect, useState } from 'react'

import download from '../components/assets/svg/download.svg'
import search from '../components/assets/svg/search.svg'
import { BASE_URL } from '../utils/constants'
import { formattingCurrency } from '../utils/formatting'
import { ExportButton } from '../components/Button/export'
import { ISimulation } from '../pages/companies/interfaces'
import { useDebounce } from '../hooks/useDebounce'

export function Dashboard() {
  const LIMIT = 10
  const [offset, setOffset] = useState(1)

  function handlePageChange(e: number) {
    setOffset(e)
  }

  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({
    items: [] as ISimulation[],
    totalItems: 0,
  })
  const [text, setText] = useState('')

  const fetchActiveUsers = useCallback(
    async (search = '') => {
      setIsLoading(true)
      try {
        const searchName = search ? `&search=${search}` : ''
        const url = `/simulation?page=${offset}&perPage=5${searchName}`
        const { data } = await api.get(url, mountHeaders())
        setData(data)
        setIsLoading(false)
      } catch (error) {
        console.error('error: ', error)
      }
    },
    [offset],
  )

  useEffect(() => {
    fetchActiveUsers()
  }, [fetchActiveUsers, offset])

  const saveAppName = useCallback(
    useDebounce(async (e: string) => {
      await fetchActiveUsers(e)
    }, 800),
    [],
  )

  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    setText(event.currentTarget.value)
    saveAppName(event.currentTarget.value)
  }

  const [loadingAll, setLoadingAll] = useState(false)

  function handleExportAll() {
    setLoadingAll(true)
    api
      .post(`/simulation/export-all`, mountHeaders())
      .then(({ data }) => {
        const fileName = data.fileName
        const link = document.createElement('a')
        const url = BASE_URL + fileName
        link.setAttribute('download', fileName)
        link.href = url
        document.body.appendChild(link)
        link.click()
        link.remove()
        setLoadingAll(false)
      })
      .catch((error) => {
        console.error('error: ', error)
        setLoadingAll(false)
      })
  }

  return (
    <>
      <Header />
      <SideBar />

      <Container>
        <SimulacoesHeader>
          <span>LISTAGEM DE SIMULAÇÕES</span>

          <SearchBody>
            <img src={search} alt="Ícone de busca" />
            <input
              placeholder="Pesquisa"
              value={text}
              onChange={handleChange}
            />
          </SearchBody>

          {!loadingAll && (
            <ExportButton
              handleExport={handleExportAll}
              labelText="Exportar Lista"
            />
          )}
          {loadingAll && (
            <ListBtn>
              <button disabled>
                <Spinner stroke="red" />
              </button>
            </ListBtn>
          )}
        </SimulacoesHeader>

        {isLoading && data?.items?.length === 0 ? (
          <Spinner stroke="red" />
        ) : (
          <Table>
            <tr>
              <th>Nº Pedido</th>
              <th>Nome</th>
              <th>Email</th>
              <th>NIF</th>
              <th>Contacto</th>
              <th>Nº Colaboradores</th>
              <th>Valor da Poupança Final</th>
              <th></th>
            </tr>
            {data?.items.map((simulacao) => {
              return (
                <tr key={simulacao.id}>
                  <td>{simulacao.numb}</td>
                  <td>{simulacao.nome}</td>
                  <td>{simulacao.email}</td>
                  <td>{simulacao.nif}</td>
                  <td>{simulacao.contacto}</td>
                  <td>{simulacao.n_colaboradores}</td>
                  <td>{formattingCurrency(simulacao.poupanca_fiscal)}</td>

                  <td>
                    <button
                      onClick={() => {
                        api
                          .post(
                            `/simulation/export/${simulacao.numb}`,
                            mountHeaders(),
                          )
                          .then(({ data }) => {
                            const fileName = data.fileName

                            const link = document.createElement('a')
                            const url = `${BASE_URL}/download/` + fileName
                            link.setAttribute('download', fileName)
                            link.href = url
                            document.body.appendChild(link)
                            link.click()
                            link.remove()
                            // reset()
                          })
                      }}
                    >
                      <img src={download} alt="Ícone de download" />
                    </button>
                  </td>
                </tr>
              )
            })}
          </Table>
        )}

        <Pagination
          limit={LIMIT}
          total={data.totalItems}
          offset={offset}
          setOffset={handlePageChange}
        />
      </Container>
    </>
  )
}
