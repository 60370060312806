import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { QueryClientProvider, QueryClient } from 'react-query'

import { Login } from './components/Login'
import { Recuperar } from './components/Recuperar'
import { Repor } from './components/Repor'
import { Dashboard } from './Dashboard'
import { Empresas } from './pages/companies'
import { EditEmpresa } from './pages/companies/EditEmpresa'
import { SimulationsByCompany } from './pages/companies/simulationsByCompany'
import { SimulationDetails } from './pages/companies/simulationsByCompany/details'
import { Users } from './Users'
import { CreateUser } from './Users/CreateUser'
import { EditUser } from './Users/EditUser'
import { AuthProvider } from './context/Auth/AuthProvider'
import { RequireAuth } from './context/Auth/RequireAuth'
import { EditSimulation } from './pages/companies/simulationsByCompany/edit'
import { Variables } from './pages/configurations/variables'
import { CreateVariable } from './pages/configurations/variables/Create'
import { EditVariable } from './pages/configurations/variables/Edit'

const queryClient = new QueryClient()
const authToken = localStorage.getItem('authToken')
const utilizador = localStorage.getItem('utilizador')

const getUser = (user: string | null) => {
  try {
    if (user) return JSON.parse(user)
  } catch (err) {
    console.error('err: ', err)
    return { is_admin: false }
  }
}
const user = getUser(utilizador)

function App() {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={authToken ? <Navigate to="/empresas" /> : <Login />}
            />
            <Route path="/login" element={<Login />} />
            <Route path="/recuperar" element={<Recuperar />} />
            <Route path="/repor/:id" element={<Repor />} />

            <Route
              path="/simulations"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />

            <Route
              path="/empresas"
              element={
                <RequireAuth>
                  <Empresas />
                </RequireAuth>
              }
            />
            <Route
              path="/empresas/:id/simulations"
              element={
                <RequireAuth>
                  <SimulationsByCompany />
                </RequireAuth>
              }
            />
            <Route
              path="/empresas/:id/simulations/:simulation_id"
              element={
                <RequireAuth>
                  <SimulationDetails />
                </RequireAuth>
              }
            />
            <Route
              path="/empresas/:id/simulations/:simulation_id/edit/:employee_id"
              element={
                <RequireAuth>
                  <EditSimulation />
                </RequireAuth>
              }
            />
            <Route
              path="/editEmpresa/:id"
              element={
                <RequireAuth>
                  <EditEmpresa />
                </RequireAuth>
              }
            />
            <Route
              path="/editEmpresa"
              element={
                <RequireAuth>
                  <EditEmpresa />
                </RequireAuth>
              }
            />

            <Route
              path="/configurations/users"
              element={
                <RequireAuth>
                  {user?.is_admin ? (
                    <Users />
                  ) : (
                    <Navigate to="/empresas" replace />
                  )}
                </RequireAuth>
              }
            />
            <Route
              path="/users/edit-user/:id"
              element={
                <RequireAuth>
                  {user?.is_admin ? (
                    <EditUser />
                  ) : (
                    <Navigate to="/empresas" replace />
                  )}
                </RequireAuth>
              }
            />
            <Route
              path="/users/create-user"
              element={
                <RequireAuth>
                  {user?.is_admin ? (
                    <CreateUser />
                  ) : (
                    <Navigate to="/empresas" replace />
                  )}
                </RequireAuth>
              }
            />

            <Route
              path="/configurations/variables"
              element={
                <RequireAuth>
                  {user?.is_admin ? (
                    <Variables />
                  ) : (
                    <Navigate to="/empresas" replace />
                  )}
                </RequireAuth>
              }
            />
            <Route
              path="/configurations/variables/:id"
              element={
                <RequireAuth>
                  {user?.is_admin ? (
                    <EditVariable />
                  ) : (
                    <Navigate to="/empresas" replace />
                  )}
                </RequireAuth>
              }
            />
            <Route
              path="/configurations/variables/create"
              element={
                <RequireAuth>
                  {user?.is_admin ? (
                    <CreateVariable />
                  ) : (
                    <Navigate to="/empresas" replace />
                  )}
                </RequireAuth>
              }
            />

            <Route
              path="*"
              element={
                authToken ? <Navigate to="/empresas" /> : <Navigate to="/" />
              }
            ></Route>
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </AuthProvider>
  )
}

export default App
