import styled from 'styled-components'

export const Container = styled.form`
  padding-top: 15vh;
  padding-left: 18vw;
  padding-right: 2vw;
`

export const EmpresasHeader = styled.div`
  padding-right: 2vw;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  span {
    font-size: 24px;
    color: var(--red);
  }
`

export const FormContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 4rem 0 0 4rem;
`
export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 8rem;

  label {
    color: var(--red);
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  span {
    font-size: 15px;
    color: var(--red);
    margin-bottom: 1rem;
  }
`

export const StyledEditInput = styled.input`
  height: 47px;
  width: 256px;

  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;

  display: flex;
  align-items: center;

  padding: 10px;
  border: none;

  margin-top: 1rem;
  margin-bottom: 1rem;

  input:focus {
    outline: none;
    border: none;
  }
`

export const StyledEditDropdown = styled.div`
  height: 47px;
  width: 256px;

  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;

  display: flex;
  align-items: center;

  padding: 10px;
  border: none;

  margin-top: 1rem;
  margin-bottom: 1rem;

  select {
    border: none;
    width: 256px;
    padding: 1rem 0.5rem;
    font-size: 0.8rem;
    opacity: 0.8;
  }

  select:focus {
    outline: none;
    border: none;
  }
`
