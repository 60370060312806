import { StyledSpinner, Container } from './styles'

interface IProps {
  stroke: string
  marginTop?: string
}

export function Spinner({ stroke, marginTop = '2rem' }: IProps) {
  const Spinner = () => (
    <StyledSpinner viewBox="0 0 50 50">
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="2"
        stroke={stroke}
      />
    </StyledSpinner>
  )

  return (
    <Container marginTop={marginTop}>
      <Spinner />
    </Container>
  )
}
