import { ChangeEventHandler, InputHTMLAttributes } from 'react'
import { Container } from './styles'

interface IOptions {
  value: string
  label: string
}

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  options: IOptions[]
  title: string
}

export const Dropdown = ({ options, title, ...props }: IProps) => {
  return (
    <Container>
      <p>{title}</p>
      <select
        value={props.value}
        onChange={
          props.onChange as unknown as ChangeEventHandler<HTMLSelectElement>
        }
      >
        {options.map((op) => {
          return (
            <option
              placeholder={props.placeholder}
              key={op.value}
              value={op.value}
            >
              {op.label}
            </option>
          )
        })}
      </select>
    </Container>
  )
}
