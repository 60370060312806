import styled from 'styled-components'

export const Container = styled.div`
  padding-top: 15vh;
  padding-left: 18vw;
  padding-right: 2vw;
`

export const UsersHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  span {
    font-size: 24px;
    color: var(--red);
  }
`

export const ListBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;

  img {
    margin-right: 0.5rem;
  }

  button {
    width: 186px;
    height: 43px;

    border-radius: 50px;
    background: var(--red);
    border: 2px solid var(--shape);

    color: var(--shape);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  button:hover {
    background: var(--shape);
    border: 2px solid var(--red);
    color: var(--red);
  }

  a {
    text-decoration: none;
  }
`

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border: 1px solid var(--tableGrey);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  th {
    border: none;
    text-align: left;
    padding: 8px;
    height: 60.92px;

    background: var(--tableGrey);
    color: var(--shape);
    font-size: 16px;
  }

  td {
    height: 60.92px;
    border: none;
    text-align: left;
    padding: 8px;

    font-size: 14px;
  }

  tr:nth-child(even) {
    background-color: var(--shape);
  }
  tr:nth-child(odd) {
    background-color: var(--tableGrey);
  }
`
