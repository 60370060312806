import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  background-color: var(--tableGrey);
  height: 30vh;
  padding: 0 20px;
  align-items: center;
  justify-content: center;
`

export const Contents = styled.div`
  display: flex;
  background-color: var(--tableGrey);
  height: 70vh;
  padding: 0 20px;
  align-items: baseline;
  justify-content: center;
  img {
    height: 138px;
    width: 200px;
  }
`

export const FormContainer = styled.div`
  display: flex;

  z-index: 5;

  background: var(--shape);
  border-radius: 10px;
  font-size: 32px;

  h2 {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: var(--red);

    padding-top: 74px;
    padding: 74 33px;
  }
  h3 {
    font-size: 16px;
    font-weight: 400;
    color: var(--text-body);
    text-align: center;

    margin-top: 20px;
    margin-bottom: 80px;
  }
  a {
    font-size: 10px;
    text-align: right;
    margin-top: 5px;
    text-decoration: none;
    color: var(--text-body);
    font-weight: 500;

    margin-bottom: 50px;
  }
`
export const Content = styled.div``

export const StyledFormWrapper = styled.form`
  display: grid;
  justify-content: space-between;
  padding: 0 60px;

  label {
    color: var(--red);
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
  }
`

export const StyledInput = styled.input`
  display: block;
  width: 100%;
  background-color: var(--shape);
  border-radius: 8px;
  border: 1px solid;
  height: 32px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 10px;
`

export const StyledTextArea = styled.textarea``

export const StyledButton = styled.button`
  height: 70px;
  margin-bottom: 30px;
  margin-top: 40px;
  border-radius: 50px;
  background-color: var(--red);
  border: none;
  color: var(--shape);
  font-size: 24px;
  font-weight: 400;
`

export const StyledFieldset = styled.fieldset``

export const StyledError = styled.div``
