import { Container } from './styles'

export function NotFoundData() {
  return (
    <Container>
      <h1>
        <span>:(</span> Nenhum dado encontrado.
      </h1>
    </Container>
  )
}
