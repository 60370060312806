import axios from 'axios'
import { Toast } from '../components/Toast'
import { BASE_URL } from '../utils/constants'

export const api = axios.create({
  baseURL: BASE_URL,
  // baseURL: 'http://localhost:8080',
})

const setupInterceptors = (token: string) => {
  axios.interceptors.request.use(
    (config) => {
      if (config?.headers) config.headers.Authorization = `Bearer ${token}`
      return config
    },
    (error) => {
      console.error('error: ', error)
      Toast({ type: 'error', message: 'Ocorreu algum erro tente mais tarde.' })
      return Promise.reject(error)
    },
  )
}

export const mountHeaders = () => {
  const token = localStorage.getItem('authToken')
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
}

export const useApi = () => ({
  validateToken: async (token: string) => {
    const response = await api.post('/auth/validar', { token })
    return response.data
  },

  signIn: async (email: string, password: string) => {
    const response = await api.post('/auth/sign-in', {
      email,
      password,
    })

    const { token } = response.data
    setupInterceptors(token)
    localStorage.setItem('authToken', token)

    return response.data
  },

  logout: () => {
    localStorage.removeItem('authToken')
  },
})
