import * as S from './styles'
import { Dropdown } from '../Dropdown'

export type ValueType = 'lt' | 'gt' | 'lte' | 'gte' | 'equals' | ''
export type LabelType = '<' | '>' | '<=' | '>=' | '='
export type OperatorType = {
  label: LabelType
  value: ValueType
}

export interface IQuery {
  operator: ValueType | LabelType
  search: string
}

interface IProps {
  setQueryEmployee: React.Dispatch<React.SetStateAction<IQuery>>
  setQueryTax: React.Dispatch<React.SetStateAction<IQuery>>
  queryTax: IQuery
  queryEmployee: IQuery
  handleFilters: (values: {
    queryEmployee: IQuery
    queryTax: IQuery
  }) => Promise<void>
}

export const Filters = ({
  setQueryEmployee,
  setQueryTax,
  handleFilters,
  queryEmployee,
  queryTax,
}: IProps) => {
  const options = [
    { label: '<', value: 'lt' },
    { label: '>', value: 'gt' },
    { label: '<=', value: 'lte' },
    { label: '>=', value: 'gte' },
    { label: '=', value: 'equals' },
  ] as OperatorType[]

  const handleChangeEmployeeOperator = (value: LabelType) => {
    setQueryEmployee(
      (state) => ({ ...state, operator: value }) as unknown as IQuery,
    )
  }

  const handleChangeTaxOperator = (value: LabelType) => {
    setQueryTax((state) => ({ ...state, operator: value }) as unknown as IQuery)
  }

  const handleChangeEmployeeInput = (value: string) => {
    setQueryEmployee((state) => ({ ...state, search: value }))
  }

  const handleChangeTaxInput = (value: string) => {
    setQueryTax((state) => ({ ...state, search: value }))
  }

  const handleCleanFilters = () => {
    const query = { operator: '>=', search: '' } as IQuery
    setQueryTax(query)
    setQueryEmployee(query)
    handleFilters({ queryEmployee: query, queryTax: query })
  }

  return (
    <S.Container>
      <S.Label htmlFor="custom-textfield">Filtrar por:</S.Label>

      <S.Body>
        <S.Content>
          <S.Label htmlFor="custom-textfield">Colaboradores:</S.Label>

          <S.Operator>
            <Dropdown
              title="Operador"
              options={options}
              value={queryEmployee?.operator}
              onChange={(e) =>
                handleChangeEmployeeOperator(e.target.value as LabelType)
              }
            />

            <S.Input
              placeholder="0"
              value={queryEmployee.search}
              onChange={(e) => handleChangeEmployeeInput(e.target.value)}
            />
          </S.Operator>
        </S.Content>

        <S.Content>
          <S.Label htmlFor="custom-textfield">Poupança Fiscal:</S.Label>

          <S.Operator>
            <Dropdown
              title="Operador"
              options={options}
              value={queryTax.operator}
              onChange={(e) =>
                handleChangeTaxOperator(e.target.value as LabelType)
              }
            />
            <S.Input
              placeholder="0,00"
              value={queryTax.search}
              onChange={(e) => handleChangeTaxInput(e.target.value)}
            />
          </S.Operator>
        </S.Content>
      </S.Body>

      <S.ContentButton>
        <S.Button className="clean" onClick={handleCleanFilters}>
          Limpar
        </S.Button>
        <S.Button onClick={() => handleFilters({ queryTax, queryEmployee })}>
          Filtrar
        </S.Button>
      </S.ContentButton>
    </S.Container>
  )
}
