import styled from 'styled-components'

export const Container = styled.div`
  padding-top: 15vh;
  padding-left: 230px;
  padding-right: 2vw;
`

export const EmpresasHeader = styled.div`
  display: flex;
  margin-bottom: 2rem;

  span {
    font-size: 24px;
    color: #ff4c66;

    & + span {
      margin-right: 1rem;
    }
  }
`

export const TitleAndSearch = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1.5rem;
  font-weight: 500;

  @media (max-width: 1538px) {
    section {
      display: flex;
      flex-direction: column;
    }
  }
`

export const ExportButtonContent = styled.table`
  display: flex;
  width: 100%;
  align-items: center;

  button {
    width: 100% !important;
    height: 50px;
  }
`

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border: 1px solid var(--tableGrey);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  th {
    border: none;
    text-align: left;
    padding: 8px;
    height: 60.92px;

    background: var(--tableGrey);
    color: var(--shape);
    font-size: 16px;
  }

  td {
    height: 60.92px;
    border: none;
    text-align: left;
    padding: 8px;

    font-size: 14px;
  }

  tr:nth-child(even) {
    background-color: var(--shape);
  }

  tr:nth-child(odd) {
    background-color: var(--tableGrey);
  }
`
