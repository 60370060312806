import { useEffect, useState, useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Header } from '../components/header'
import { SideBar } from '../components/sideBar'
import { Spinner } from '../components/Spinner'
import Pagination from '../components/Pagination'

import check from '../components/assets/svg/check.svg'
import notCheck from '../components/assets/svg/close.svg'
import edit from '../components/assets/svg/edit.svg'

import { Container, ListBtn, Table, UsersHeader } from './styles'

import { api, mountHeaders } from '../services/api'
import { IError } from '../pages/companies/interfaces'

interface User {
  id: null | undefined
  name: string
  email: string
  is_admin: boolean
  is_active: boolean
}

interface IUser {
  items: User[]
  totalItems: number
}

export function Users() {
  const LIMIT = 10
  const [offset, setOffset] = useState(1)
  const navigate = useNavigate()

  const handlePageChange = (e: number) => setOffset(e)

  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<IUser>({ items: [], totalItems: 0 })

  const fetchActiveUsers = useCallback(
    async (e = null) => {
      setIsLoading(true)
      try {
        const search = e ? `&search=${e}` : ''
        const url = `/users?page=${offset}&perPage=10${search}`
        const { data } = await api.get(url, mountHeaders())
        setData(data)
        setIsLoading(false)
      } catch (err) {
        const error = err as IError
        setIsLoading(false)
        console.error('error: ', error)
        const hasInvalidToken =
          error?.response?.data?.message?.includes('Invalid Token')
        if (hasInvalidToken) {
          localStorage.removeItem('authToken')
          navigate('/login')
        }
      }
    },
    [offset],
  )

  useEffect(() => {
    fetchActiveUsers()
  }, [fetchActiveUsers, offset])

  return (
    <>
      <Header />
      <SideBar />
      <Container>
        <UsersHeader>
          <span>UTILIZADORES</span>

          <ListBtn>
            <Link to="/users/create-user">
              <button>CRIAR UTILIZADOR</button>
            </Link>
          </ListBtn>
        </UsersHeader>

        <Table>
          <tr>
            <th>Nome</th>
            <th>Email</th>
            <th>Cargo</th>
            <th>Ativo</th>
            <th>Ação</th>
          </tr>

          {data?.items?.length > 0 &&
            !isLoading &&
            data?.items.map((users) => {
              return (
                <tr key={users.id}>
                  <td>{users.name}</td>
                  <td>{users.email}</td>

                  <td>
                    {users.is_admin ? <p>Administrador</p> : <p>Utilizador</p>}
                  </td>
                  <td>
                    {users.is_active ? (
                      <img src={check} alt="Ícone de ativo" />
                    ) : (
                      <img src={notCheck} alt="Ícone de inativo" />
                    )}
                  </td>

                  <td>
                    <Link to={'/users/edit-user/' + users.id}>
                      <img src={edit} alt="Ícone de editar" />
                    </Link>
                  </td>
                </tr>
              )
            })}
        </Table>

        {isLoading && <Spinner stroke="red" />}

        <Pagination
          limit={LIMIT}
          total={data.totalItems}
          offset={offset}
          setOffset={handlePageChange}
        />
      </Container>
    </>
  )
}
