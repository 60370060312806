import styled from 'styled-components'

export const Container = styled.div``

export const UL = styled.ul`
  display: flex;
  float: right;
  list-style: none;
  margin-top: 1rem;

  li + li {
    margin-left: 0.5rem;
  }
`

export const BtnPaginationActive = styled.button`
  background: none;
  font-weight: bold;
  outline: 1px solid var(--red);
  border: none;
  color: var(--red);
  width: 32px;
  height: 32px;
  border-radius: 4px;
`

export const BtdPaginationUnactive = styled.button`
  background: none;
  font-weight: bold;
  outline: 1px solid var(--grey);
  border: none;
  color: var(--darkGrey);
  width: 32px;
  height: 32px;
  border-radius: 4px;
`

export const BtnPaginationImg = styled.button`
  background: none;
  font-weight: bold;
  outline: 1px solid var(--grey);
  border: none;
  color: var(--darkGrey);
  width: 32px;
  height: 32px;
  border-radius: 4px;

  :disabled {
    background: #919eab;
  }
`
