import { useEffect, useState } from 'react'

type Timer = ReturnType<typeof setTimeout>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDebounce<T extends (...args: any[]) => any>(
  func: T,
  delay: number,
): T {
  const [timer, setTimer] = useState<Timer | null>(null)

  useEffect(() => {
    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [timer])

  return ((...args: Parameters<T>) => {
    if (timer) clearTimeout(timer)
    const timeout = setTimeout(() => func(...args), delay)
    setTimer(timeout)
  }) as T
}
