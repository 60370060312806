import { Navigate } from 'react-router-dom'

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const authToken = localStorage.getItem('authToken')

  if (authToken === null) {
    return <Navigate to="/" />
  }

  return children
}
