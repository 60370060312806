import { Link } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'

import { Header } from '../../../components/header'
import { SideBar } from '../../../components/sideBar'

import { api, mountHeaders } from '../../../services/api'

import { Container, ListBtn, Table, VariablesHeader } from './styles'
import { IError, IVariables } from '../../companies/interfaces'
import { Spinner } from '../../../components/Spinner'
import Pagination from '../../../components/Pagination'
import edit from '../../../components/assets/svg/edit.svg'
import { formatDate } from '../../../utils/formatting'
import deleteIcon from '../../../components/assets/svg/delete.svg'

export const Variables = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [offset, setOffset] = useState(1)
  const [data, setData] = useState({
    items: [] as IVariables[],
    totalItems: 0,
  })
  const LIMIT = 10

  const handlePageChange = (e: number) => setOffset(e)

  const fetchVariables = useCallback(async () => {
    setIsLoading(true)
    try {
      const url = `/settings/variables?page=${offset}&perPage=10`
      const { data } = await api.get(url, mountHeaders())
      setData({
        ...data,
        items: data.items.map((item: IVariables) => {
          return {
            ...item,
            created_at: formatDate(item.created_at),
            updated_at: formatDate(item.updated_at),
          }
        }),
      })
      setIsLoading(false)
    } catch (err) {
      const error = err as IError
      console.log('error: ', error)
      setIsLoading(false)
    }
  }, [offset])

  useEffect(() => {
    fetchVariables()
  }, [])

  const handleDeleteVariable = async (id: string) => {
    setIsLoading(true)
    try {
      await api.delete(`entidades/${id}`, mountHeaders())
      await fetchVariables()
    } catch (error) {
      console.error('error: ', error)
      setIsLoading(false)
    }
  }

  return (
    <>
      <Header />
      <SideBar />
      <Container>
        <VariablesHeader>
          <span>VARIÁVEIS DO SISTEMA</span>

          <ListBtn>
            <Link to="/configurations/variables/create">
              <button>CRIAR VARIÁVEL</button>
            </Link>
          </ListBtn>
        </VariablesHeader>

        <Table>
          <tr>
            <th>Nome</th>
            <th>Data Criação</th>
            <th>Usuário</th>
            <th>Data Update</th>
            <th>Usuário</th>
            <th>Ação</th>
          </tr>

          {data?.items?.length > 0 &&
            !isLoading &&
            data?.items.map((variable) => {
              return (
                <tr key={variable.id}>
                  <td>{variable.name}</td>
                  <td>{variable.created_at}</td>
                  <td>{variable.user_create}</td>
                  <td>{variable.updated_at}</td>
                  <td>{variable.user_update}</td>
                  <td
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1rem',
                    }}
                  >
                    <Link to={'/configurations/variables/' + variable.id}>
                      <img src={edit} alt="Ícone de editar" />
                    </Link>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleDeleteVariable(variable.id)}
                    >
                      <img src={deleteIcon} alt="Ícone de delete" />
                    </span>
                  </td>
                </tr>
              )
            })}
        </Table>

        {isLoading && <Spinner stroke="red" />}

        <Pagination
          limit={LIMIT}
          total={data.totalItems}
          offset={offset}
          setOffset={handlePageChange}
        />
      </Container>
    </>
  )
}
