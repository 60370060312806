import styled from 'styled-components'

interface IProps {
  width?: string
}

export const SearchBody = styled.div<IProps>`
  height: 40px;
  width: ${(props) => props.width || '350px'};

  @media (max-width: 1538px) {
    width: 180px;
  }

  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;

  display: flex;
  align-items: center;

  padding: 10px;

  input {
    margin-left: 0.5rem;
    height: 100%;
    width: 100%;
    border: none;
  }

  input:focus {
    outline: none;
  }
`
