import { toast } from 'react-toastify'
import { IError } from '../../types'

interface IProps {
  type: 'error' | 'success' | 'info'
  error?: unknown
  message?: string | JSX.Element
}
export const Toast = ({ type, error, message }: IProps) => {
  const err = error as IError
  const toastMessage =
    err?.response?.data?.message ||
    message ||
    'Ocorreu um erro, tente mais tarde'
  return toast[type](toastMessage)
}
