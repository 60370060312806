import { createContext } from 'react'
import { User } from '../../types/User'

export type AuthContextType = {
  utilizador: User | null
  signIn: (email: string, password: string) => Promise<{ token: string }>
  signOut: () => void
}

export const AuthContext = createContext<AuthContextType>(null!)
