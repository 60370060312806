export const treatmentForErrors = ({
  status,
  message,
}: {
  status: number
  message?: string
}) => {
  switch (status) {
    case 400:
      return message || 'Solicitação inválida'
    case 404:
      return message || 'Recurso não encontrado'
    default:
      return 'Ocorreu algum erro, tente mais tarde.'
  }
}
