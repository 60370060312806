import * as yup from 'yup'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'

import { api, mountHeaders } from '../../../../services/api'

import { Header } from '../../../../components/header'
import { SideBar } from '../../../../components/sideBar'
import { Spinner } from '../../../../components/Spinner'

import { ErrorMessage } from '../Create/styles'
import {
  EmpresasHeader,
  Container,
  DetailTitule,
  ProfileForm,
  StyledFormWrapper,
  StyledEditInput,
  StyledEditSelect,
  ListBtn,
} from './styles'
import { IVariables } from '../../../companies/interfaces'

const updateVariableFormSchema = yup.object().shape({
  name: yup.string().required('Nome Obrigatório'),
  value: yup.string().required('Campo Obrigatório'),
  using_system: yup.string().required('Campo Obrigatório'),
})

type FormData = {
  name: string
  value: string
  using_system: 'SALARY'
}

export const EditVariable = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [variable, setVariable] = useState({} as IVariables)
  const navigate = useNavigate()
  const { id } = useParams()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(updateVariableFormSchema),
  })

  const fetchVariable = useCallback(async () => {
    const { data } = await api.get(`/settings/variables/${id}`, mountHeaders())
    setVariable(data)
    reset(data)
  }, [])

  useEffect(() => {
    fetchVariable()
  }, [fetchVariable])

  const updateVariable = handleSubmit((data) => {
    const dataFormatted = data as unknown as IVariables
    const body = {
      id,
      name: dataFormatted.name,
      value: dataFormatted.value,
      using_system: dataFormatted.using_system,
    }
    setLoading(true)
    api
      .put(`/settings/variables/${id}`, body, mountHeaders())
      .then(() => {
        setLoading(false)
        navigate('/configurations/variables')
      })
      .catch((error) => {
        setLoading(false)
        console.error(error.response.data.message)
        setError(error.response.data.message)
      })
  })

  return (
    <>
      <Header />
      <SideBar />
      <Container>
        <EmpresasHeader>
          <span>EDITAR VARIÁVEL</span>
        </EmpresasHeader>

        <DetailTitule>
          {/* <img src={profile} alt="Ícone de usuário" /> */}

          <span>{variable?.name}</span>
        </DetailTitule>

        <form onSubmit={updateVariable}>
          <ProfileForm>
            <StyledFormWrapper>
              <label>NOME</label>
              <StyledEditInput
                placeholder="Nome"
                type="text"
                {...register('name')}
              />
              {errors.name && (
                <ErrorMessage>{errors.name.message}</ErrorMessage>
              )}
            </StyledFormWrapper>

            <StyledFormWrapper>
              <label>VALOR</label>
              <StyledEditInput
                {...register('value')}
                placeholder="Valor"
                type="text"
              />
              {errors.value && <span>{errors.value.message}</span>}
            </StyledFormWrapper>

            <StyledFormWrapper>
              <label>ONDE UTILIZAR</label>
              <StyledEditSelect id="cargo" {...register('using_system')}>
                <option value={'SALARY'}>Salário Base</option>
                <option value={'OTHER_1'}>OUTRO 1</option>
                <option value={'OTHER_2'}>OUTRO 2</option>
                <option value={'OTHER_3'}>OUTRO 3</option>
                <option value={'OTHER_4'}>OUTRO 4</option>
              </StyledEditSelect>
              {errors.using_system && (
                <span>{errors.using_system.message}</span>
              )}
            </StyledFormWrapper>
          </ProfileForm>

          {!loading && <ListBtn type="submit">EDITAR VARIÁVEL</ListBtn>}

          {!error ? (
            loading && <Spinner stroke="red" />
          ) : (
            <ErrorMessage>{error}</ErrorMessage>
          )}
        </form>
      </Container>
    </>
  )
}
