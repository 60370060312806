import { useEffect, useState } from 'react'
import { User } from '../../types/User'
import { AuthContext } from './AuthContext'
import { useApi } from '../../services/api'
import { Toast } from '../../components/Toast'
import { treatmentForErrors } from '../../shared/errors'

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [utilizador, setUser] = useState<User | null>(null)
  const api = useApi()

  useEffect(() => {
    const validateToken = async () => {
      const storageData = localStorage.getItem('authToken')
      if (storageData) {
        const data = await api.validateToken(storageData)
        if (data.user) {
          setUser(data.user)
        }
      }
    }
    validateToken()
  }, [api])

  const signIn = async (email: string, password: string) => {
    const data = await api.signIn(email, password)

    if (data?.token) {
      setUser(data.utilizador)
      saveUser(data.utilizador)
      setToken(data.token)
      Toast({ type: 'success', message: 'Login realizado com sucesso' })
      return data
    }

    const message = treatmentForErrors({
      status: data.statusCode,
    })

    Toast({ type: 'error', message })
  }

  const signOut = async () => {
    setUser(null)
    saveUser('')
    setToken('')
    api.logout()
  }

  const setToken = (token: string) => {
    localStorage.setItem('authToken', token)
  }

  const saveUser = (token: string) => {
    localStorage.setItem('utilizador', JSON.stringify(token))
  }

  return (
    <AuthContext.Provider value={{ utilizador, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  )
}
