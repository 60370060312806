import styled from 'styled-components'

export const Container = styled.div`
  padding-top: 15vh;
  padding-left: 18vw;
  padding-right: 2vw;
`

export const EmpresasHeader = styled.div`
  padding-right: 2vw;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  span {
    font-size: 24px;
    color: var(--red);
  }
`

export const StyledEditInput = styled.input`
  height: 47px;
  width: 236px;

  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;

  display: flex;
  align-items: center;

  padding: 10px;
  border: none;

  margin-top: 1rem;
  margin-bottom: 1rem;
  outline: none;
`

export const StyledEditSelect = styled.select`
  height: 47px;
  width: 236px;

  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;

  display: flex;
  align-items: center;

  padding: 10px;
  border: none;

  margin-top: 1rem;
  margin-bottom: 1rem;
  outline: none;

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`

export const ProfileForm = styled.div`
  display: flex;
  margin-top: 2rem;
`

export const ListBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  padding: 0px 2px 1px;
  margin-left: 1rem;

  width: 186px;
  height: 43px;

  border-radius: 50px;
  background: var(--red);
  border: 2px solid var(--shape);

  color: var(--shape);

  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    background: var(--shape);
    border: 2px solid var(--red);
    color: var(--red);
  }
`

export const StyledFormWrapper = styled.div`
  display: grid;
  justify-content: space-between;
  padding: 0 20px;

  label {
    color: var(--red);
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }
`

export const ErrorMessage = styled.p`
  color: var(--red);
`
