import styled from 'styled-components'

export const Container = styled.div`
  padding-top: 15vh;
  padding-left: 18vw;
  padding-right: 2vw;
`

export const EmpresasHeader = styled.div`
  padding-right: 2vw;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  span {
    font-size: 24px;
    color: var(--red);
  }
`

export const DetailTitule = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
  margin-left: 3rem;

  img {
    width: 95px;
    height: 95px;
    margin-right: 3rem;
  }

  span {
    font-size: 24px;
  }
`

export const StyledFormWrapper = styled.form`
  display: grid;
  justify-content: space-between;
  padding: 0 60px;
  margin-left: 8rem;

  label {
    color: var(--red);
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  span {
    font-size: 15px;
    color: var(--red);
    margin-bottom: 1rem;
  }
`

export const StyledEditInput = styled.input`
  height: 47px;
  width: 256px;

  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;

  display: flex;
  align-items: center;

  padding: 10px;
  border: none;

  margin-top: 1rem;
  margin-bottom: 1rem;

  input:focus {
    outline: none;
    border: none;
  }
`
