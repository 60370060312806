import { Header } from '../../components/header'
import { SideBar } from '../../components/sideBar'
import * as S from './styles'

import view from '../../components/assets/svg/carbon_view.svg'
import edit from '../../components/assets/svg/edit.svg'
import deleteIcon from '../../components/assets/svg/delete.svg'

import { Link, useNavigate } from 'react-router-dom'
import { api, mountHeaders } from '../../services/api'
import { Spinner } from '../../components/Spinner'
import { FormEvent, useCallback, useEffect, useState } from 'react'
import Pagination from '../../components/Pagination'
import { useDebounce } from '../../hooks/useDebounce'
import { formattingCurrency } from '../../utils/formatting'
import { ExportButton } from '../../components/Button/export'
import { Filters, IQuery } from './simulationsByCompany/components/filters'
import { validationToken } from '../../utils/validateToken'
import { SearchBody } from '../../components/SearchBar/styles'
import searchIMg from '../../components/assets/svg/search.svg'
import { ICompany } from './interfaces'
import { IResponse } from '../../types'

interface IFetchCompanies {
  search: null | string
  employeeOperator: null | string
  count: null | string
  tax: null | string
  taxOperator: null | string
  page: null | number
}
export function Empresas() {
  const [offset, setOffset] = useState(1)
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [companies, setCompanies] = useState<IResponse<ICompany>>({
    items: [],
    totalItems: 0,
  })
  const [queryEmployee, setQueryEmployee] = useState({
    operator: 'gte',
    search: '',
  } as IQuery)
  const [queryTax, setQueryTax] = useState({
    operator: 'gte',
    search: '',
  } as IQuery)

  const navigate = useNavigate()

  const LIMIT = 6

  const handlePageChange = (e: number) => setOffset(e)

  const fetchCompanies = useCallback(
    async ({
      search = null,
      employeeOperator = null,
      count = null,
      tax = null,
      taxOperator = null,
    }: IFetchCompanies) => {
      setIsLoading(true)
      try {
        const searchParam = search ? `&search=${search}` : ''

        const searchEmployeeParam = count
          ? `&employeeCount=${count}&employeeOperator=${employeeOperator}`
          : ''

        const searchTaxParam = tax
          ? `&tax=${tax}&taxOperator=${taxOperator}`
          : ''

        const params = `${searchParam}${searchEmployeeParam}${searchTaxParam}`
        const url = `/entidades?page=${offset}&perPage=${LIMIT}${params}`

        const { data } = await api.get<IResponse<ICompany>>(url, mountHeaders())
        const formattedCompanies = data.items.map((company) => {
          const tax = company.simulations.map((item) => item.poupanca_fiscal)
          return {
            ...company,
            tax: tax.length > 0 ? Math.max(...tax) : 0,
          }
        })
        setCompanies({ ...data, items: formattedCompanies })
        setIsLoading(false)
      } catch (error) {
        validationToken(error, navigate)
        setIsLoading(false)
      }
    },
    [offset, navigate],
  )

  useEffect(() => {
    fetchCompanies({} as IFetchCompanies)
  }, [fetchCompanies, offset])

  const saveAppName = useCallback(
    useDebounce(async (e: string) => {
      await fetchCompanies({ search: e } as IFetchCompanies)
    }, 800),
    [],
  )

  const fetchCompaniesWithParams = useCallback(
    useDebounce(
      async ({
        search = null,
        count = null,
        tax = null,
        employeeOperator = null,
        taxOperator = null,
      }: IFetchCompanies) => {
        await fetchCompanies({
          search,
          count,
          tax,
          employeeOperator,
          taxOperator,
          page: offset,
        })
      },
      1000,
    ),
    [fetchCompanies, offset, search],
  )

  const handleChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      setSearch(event.currentTarget.value)
      saveAppName(event.currentTarget.value)
    },
    [saveAppName],
  )

  const handleExportAll = () => {
    setIsLoading(true)
    api
      .post(`/entidades/export`, { responseType: 'text' }, mountHeaders())
      .then(({ data }) => {
        const blob = new Blob([data], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `Companies.csv`)
        document.body.appendChild(link)
        link.click()
        link.remove()
        setIsLoading(false)
      })
      .catch((error) => {
        console.error('error: ', error)
        setIsLoading(false)
      })
  }

  const handleDeleteCompany = async (id: string) => {
    setIsLoading(true)
    try {
      await api.delete(`entidades/${id}`, mountHeaders())
      await fetchCompanies({} as IFetchCompanies)
    } catch (error) {
      console.error('error: ', error)
      setIsLoading(false)
    }
  }

  const handleFilters = useCallback(
    async (values: { queryEmployee: IQuery; queryTax: IQuery }) => {
      setOffset(1)
      await fetchCompaniesWithParams({
        search,
        count: values.queryEmployee.search,
        employeeOperator: values.queryEmployee.operator,
        tax: values.queryTax.search,
        taxOperator: values.queryTax.operator,
        page: 1,
      })
    },
    [fetchCompaniesWithParams],
  )

  return (
    <>
      <Header />
      <SideBar />
      <S.Container>
        <S.EmpresasHeader>
          <S.TitleAndSearch>
            <section>
              <span>LISTAGEM DE </span>
              <span>EMPRESAS</span>
            </section>
            <SearchBody width="350px">
              <img src={searchIMg} alt="lupa de busca" />
              <input
                placeholder="Pesquisa"
                value={search}
                onChange={handleChange}
              />
            </SearchBody>
            <S.ExportButtonContent>
              <ExportButton
                handleExport={handleExportAll}
                labelText="Exportar Lista"
              />
            </S.ExportButtonContent>
          </S.TitleAndSearch>

          <Filters
            queryEmployee={queryEmployee}
            queryTax={queryTax}
            setQueryEmployee={setQueryEmployee}
            setQueryTax={setQueryTax}
            handleFilters={handleFilters}
          />
        </S.EmpresasHeader>

        <S.Table style={{ marginBottom: isLoading ? '1rem' : '0' }}>
          <tr>
            <th>Empresa</th>
            <th>E-mail</th>
            <th>NIF</th>
            <th>Contacto</th>
            <th>Nº Colaboradores</th>
            <th>Poupança Fiscal</th>
            <th style={{ paddingLeft: '2rem' }}>Ações</th>
          </tr>

          {companies?.items?.length > 0 &&
            !isLoading &&
            companies?.items.map((company) => {
              return (
                <tr key={company.id}>
                  <td>{company.nome}</td>
                  <td>{company.email}</td>
                  <td>{company.nif}</td>
                  <td>{company.contacto}</td>
                  <td>{company.n_colaboradores}</td>
                  <td>{formattingCurrency(company?.tax || 0)}</td>
                  <td
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1rem',
                    }}
                  >
                    <Link to={`/empresas/${company.id}/simulations`}>
                      <img src={view} alt="Ícone de detalhes" />
                    </Link>
                    <Link to={'/editEmpresa/' + company.id}>
                      <img src={edit} alt="Ícone de editar" />
                    </Link>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleDeleteCompany(company.id)}
                    >
                      <img src={deleteIcon} alt="Ícone de delete" />
                    </span>
                  </td>
                </tr>
              )
            })}
        </S.Table>

        {isLoading && <Spinner stroke="red" />}

        {!isLoading && (
          <Pagination
            limit={LIMIT}
            total={companies.totalItems}
            offset={offset}
            setOffset={handlePageChange}
          />
        )}
      </S.Container>
    </>
  )
}
