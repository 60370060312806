import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { SideBar } from '../../../../components/sideBar'
import {
  Container,
  EmpresasHeader,
  StyledEditInput,
  FormContainer,
  FormWrapper,
  StyledEditDropdown,
} from './styles'

import { Header } from '../../../../components/header'
import { api, mountHeaders } from '../../../../services/api'
import { Spinner } from '../../../../components/Spinner'
import { IEmployee, ISimulation, TypeTermsAPI } from '../../interfaces'
import { ButtonWithBack } from '../../../../components/ButtonWithBack'
import {
  formattingCurrency,
  formatDate,
  formatContractTypeToAPI,
} from '../../../../utils/formatting'

type FormData = {
  nome: string
  data_nascimento: string
  contract_initial_date: string
  data_efetivo: string
  contract_type: string
  contract_duration: number
  paid: string
}

interface ISendMail {
  email: string
  nome: string
  tax_benefit: string
}

const updateSimulationFormSchema = yup.object().shape({
  nome: yup.string().required('Nome obrigatório'),
  // .matches(/^[aA-zZ\s]+$/, 'Isso não é um nome valido'),
  data_nascimento: yup.string().required('Data de nascimento é obrigatória'),
  contract_initial_date: yup
    .string()
    .required('Data inicial de contrato obrigatória'),
  data_efetivo: yup.string().required('Data de efetividade é obrigatória'),
  paid: yup.string().required('Remuneração base é obrigatória'),
  contract_type: yup.string().required('Tipo de contrato obrigatório'),
  contract_duration: yup.number(),
})

export function EditSimulation() {
  const navigate = useNavigate()
  const { id, simulation_id, employee_id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [simulation, setSimulation] = useState({} as ISimulation)

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    resolver: yupResolver(updateSimulationFormSchema),
  })

  const options = [
    { value: 'with_term', label: 'Com Termo' },
    { value: 'without_term', label: 'Sem Termo' },
    { value: 'uncertain_term', label: 'Termo Incerto' },
  ]

  const format = (date: string) => formatDate(date, 'yyyy-MM-dd') as string

  const formatEmployee = useMemo(
    () =>
      (employee: IEmployee[]): FormData =>
        employee.reduce((acc: FormData, curr: IEmployee) => {
          acc = {
            nome: curr.nome,
            data_nascimento: format(curr.data_nascimento),
            contract_type: curr.terms,
            contract_initial_date: format(curr.contract_initial_date),
            data_efetivo: format(curr.data_efetivo),
            contract_duration: curr.contract_duration,
            paid: formattingCurrency(curr.remuneracao_mensal / 100) as string,
          }
          return acc
        }, {} as FormData),
    [],
  )

  const fetchSimulations = useCallback(async () => {
    setIsLoading(true)
    try {
      const url = `/simulation/by-employee-id/${simulation_id}/${employee_id}`
      const { data } = await api.get(url, mountHeaders())
      const employeeFormatted = formatEmployee(data.Employee)
      setSimulation(data)
      reset(employeeFormatted)
      setIsLoading(false)
    } catch (error: unknown) {
      console.error('error: ', error)
      setIsLoading(false)
    }
  }, [employee_id, formatEmployee, reset, simulation_id])

  useEffect(() => {
    const fetchData = async () => await fetchSimulations()
    fetchData()
  }, [fetchSimulations])

  const handleGoBack = () =>
    navigate(`/empresas/${id}/simulations/${simulation_id}`)

  const formatPaid = (paid: string): number =>
    +paid.replace('€', '').replace(',', '').replaceAll('.', '')

  const addEuroSymbolAndDecimal = (value: string) => {
    if (!value || value.length < 3) return value
    const decimalIndex = value.length - 2
    let formattedValue = `${value.slice(0, decimalIndex)},${value.slice(
      decimalIndex,
    )} €`
    const decimalSeparatorIndex = formattedValue.indexOf(',')
    const integerPart = formattedValue.slice(0, decimalSeparatorIndex)
    const decimalPart = formattedValue.slice(decimalSeparatorIndex)
    formattedValue =
      integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + decimalPart
    return formattedValue
  }

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value
    const sanitizedValue = inputValue.replace(/\D/g, '')
    const formattedValue = addEuroSymbolAndDecimal(sanitizedValue)
    e.currentTarget.value = formattedValue
  }

  const sendMail = async ({ email: to, nome, tax_benefit }: ISendMail) => {
    const subject = 'Beneficio calculado'

    const variables = {
      name: nome,
      beneficio: tax_benefit,
    }

    if (!to || !tax_benefit || !nome) return

    try {
      await api.post('/simulations/send-email', {
        to,
        variables,
        subject,
      })
    } catch (error) {
      console.error('error: ', error)
    }
  }

  const handleEditSimulation = handleSubmit((data) => {
    setIsLoading(true)
    const url = `/simulation/poupar/${simulation_id}/${employee_id}`
    const body = {
      ...data,
      terms: formatContractTypeToAPI(data.contract_type as TypeTermsAPI),
      paid: +formatPaid(data.paid),
      contract_initial_date: new Date(data.contract_initial_date),
      data_efetivo: new Date(data.data_efetivo),
      data_nascimento: new Date(data.data_nascimento),
    }

    api
      .put(url, body, mountHeaders())
      .then(({ data }) => {
        setIsLoading(false)
        reset()
        handleGoBack()
        sendMail(data)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Error: ', error)
      })
  })

  return (
    <>
      <Header />
      <SideBar />
      <Container onSubmit={handleEditSimulation}>
        <EmpresasHeader>
          <span>EDITAR DADOS DA SIMULAÇÃO | {simulation?.numb || ''}</span>
        </EmpresasHeader>

        <FormContainer>
          <FormWrapper>
            <label>NOME</label>
            <StyledEditInput type="nome" {...register('nome')} />
            {errors.nome && <span>{errors.nome.message}</span>}

            <label>DATA DE NASCIMENTO</label>
            <StyledEditInput type="date" {...register('data_nascimento')} />
            {errors.data_nascimento && (
              <span>{errors.data_nascimento.message}</span>
            )}

            <label>TIPO DE CONTRATO</label>
            <StyledEditDropdown>
              <select {...register('contract_type')}>
                {options.map((op) => {
                  return (
                    <option key={op.value} value={op.value}>
                      {op.label}
                    </option>
                  )
                })}
              </select>
            </StyledEditDropdown>
            {errors.contract_type && (
              <span>{errors.contract_type.message}</span>
            )}

            <label>DATA DO CONTRATO INICIAL</label>
            <StyledEditInput
              type="date"
              {...register('contract_initial_date')}
            />
            {errors.contract_initial_date && (
              <span>{errors.contract_initial_date.message}</span>
            )}
          </FormWrapper>

          <FormWrapper>
            <label>DURAÇÃO DO CONTRATO INICIAL</label>
            <StyledEditInput type="number" {...register('contract_duration')} />
            {errors.contract_duration && (
              <span>{errors.contract_duration.message}</span>
            )}

            <label>DATA DE EFETIVIDADE</label>
            <StyledEditInput {...register('data_efetivo')} type="date" />
            {errors.data_efetivo && <span>{errors.data_efetivo.message}</span>}

            <label>REMUNERAÇÃO BASE</label>
            <StyledEditInput
              type="text"
              {...register('paid')}
              onChange={handleChange}
            />
            {errors.paid && <span>{errors.paid.message}</span>}
          </FormWrapper>
        </FormContainer>

        {!isLoading && <ButtonWithBack handleGoBack={handleGoBack} />}

        {isLoading && <Spinner stroke="red" />}
      </Container>
    </>
  )
}
