import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { api, mountHeaders } from '../../../../services/api'

import { Header } from '../../../../components/header'
import { SideBar } from '../../../../components/sideBar'
import { Spinner } from '../../../../components/Spinner'

import {
  Container,
  EmpresasHeader,
  ListBtn,
  ProfileForm,
  StyledEditInput,
  StyledFormWrapper,
  ErrorMessage,
  StyledEditSelect,
} from './styles'

type FormData = {
  name: string
  value: string
  using_system: 'SALARY'
}

const createVariableFormSchema = yup.object().shape({
  name: yup.string().required('Nome Obrigatório'),
  value: yup.string().required('Campo Obrigatório'),
  using_system: yup.string().required('Campo Obrigatório'),
})

export function CreateVariable() {
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(createVariableFormSchema),
  })

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const createVariable = handleSubmit((data) => {
    setLoading(true)
    api
      .post(`/settings/variables`, data, mountHeaders())
      .then(() => {
        setLoading(false)
        navigate('/configurations/variables')
      })
      .catch((error) => {
        setLoading(false)
        console.error(error.response.data.message)
        setError(error.response.data.message)
      })
  })

  return (
    <>
      <Header />
      <SideBar />
      <Container>
        <EmpresasHeader>
          <span>CRIAR NOVA VARIÁVEL</span>
        </EmpresasHeader>

        <form onSubmit={createVariable}>
          <ProfileForm>
            <StyledFormWrapper>
              <label>NOME</label>
              <StyledEditInput
                placeholder="Nome"
                type="text"
                {...register('name')}
              />
            </StyledFormWrapper>
            {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}

            <StyledFormWrapper>
              <label>VALOR</label>
              <StyledEditInput
                placeholder="Valor"
                type="text"
                {...register('value')}
              />
            </StyledFormWrapper>
            {errors.value && (
              <ErrorMessage>{errors.value.message}</ErrorMessage>
            )}

            <StyledFormWrapper>
              <label>ONDE UTILIZAR</label>
              <StyledEditSelect {...register('using_system')}>
                <option value={'SALARY'}>Salário Base</option>
                <option value={'OTHER_1'}>OUTRO 1</option>
                <option value={'OTHER_2'}>OUTRO 2</option>
                <option value={'OTHER_3'}>OUTRO 3</option>
                <option value={'OTHER_4'}>OUTRO 4</option>
              </StyledEditSelect>
            </StyledFormWrapper>
          </ProfileForm>

          {!loading && <ListBtn type="submit">CRIAR VARIÁVEL</ListBtn>}

          {!error ? (
            loading && <Spinner stroke="red" />
          ) : (
            <ErrorMessage>{error}</ErrorMessage>
          )}
        </form>
      </Container>
    </>
  )
}
