import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { Header } from '../../components/header'
import { SideBar } from '../../components/sideBar'
import {
  Container,
  DetailTitule,
  EmpresasHeader,
  ListBtn,
  PassTitule,
  ProfileForm,
  StyledEditInput,
  StyledEditSelect,
  StyledFormWrapper,
} from './styles'

import profile from '../../components/assets/svg/user.svg'
import { api, mountHeaders } from '../../services/api'
import { useEffect, useState } from 'react'
import { Spinner } from '../../components/Spinner'

type PasswordFormData = {
  current_password: string
  password: string
  confirm_password: string
}

type UserFormData = {
  id: null | undefined
  name: string
  email: string
  is_admin: boolean
  is_active: boolean
}

export function EditUser() {
  const updateUserFormSchema = yup.object().shape({
    name: yup.string().required('Nome obrigatório'),
    // .matches(/^[aA-zZ\s]+$/, 'Isso não é um nome valido'),
    email: yup.string().required('Email obrigatório').email('Formato invalido'),
    is_admin: yup.boolean().required('Campo obrigatório'),
    is_active: yup.boolean().required('Campo obrigatório'),
  })

  const updatePasswordFormSchema = yup.object().shape({
    password: yup.string().required('A senha é obrigatória'),
    confirm_password: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Senhas não conferem.'),
  })

  const { id } = useParams()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<UserFormData>({
    resolver: yupResolver(updateUserFormSchema),
  })

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: pass_errors },
  } = useForm<PasswordFormData>({
    resolver: yupResolver(updatePasswordFormSchema),
  })

  useEffect(() => {
    api.get(`/users/${id}`, mountHeaders()).then((response) => {
      reset(response.data)
    })
  }, [id, reset])

  const fetchActiveUsers = () => {
    return api.get(`/users/${id}`, mountHeaders())
  }

  const { data } = useQuery(`/users/${id}`, fetchActiveUsers)

  const [loadingUser, setLoadingUser] = useState(false)
  const [loadingPass, setLoadingPass] = useState(false)

  const updateUser = handleSubmit((data) => {
    setLoadingUser(true)
    api
      .put(`/users/${id}`, data, mountHeaders())
      .then(() => {
        setLoadingUser(false)
        navigate('/configurations/users')
      })
      .catch((error) => {
        setLoadingUser(false)
        console.error('Erro: ', error)
      })
  })

  const updatePassword = handleSubmit2((data) => {
    setLoadingPass(true)
    api
      .patch(`/auth/change-password/${id}`, data, mountHeaders())
      .then(() => {
        setLoadingPass(false)
        navigate('/configurations/users')
      })
      .catch((error) => {
        setLoadingPass(false)
        console.error('Erro: ', error)
      })
  })

  return (
    <>
      <Header />
      <SideBar />
      <Container>
        <EmpresasHeader>
          <span>EDITAR UTILIZADOR</span>
        </EmpresasHeader>

        <DetailTitule>
          <img src={profile} alt="Ícone de usuário" />

          <span>{data?.data.nome}</span>
        </DetailTitule>

        <form onSubmit={updateUser}>
          <ProfileForm>
            <StyledFormWrapper>
              <label>NOME</label>
              <StyledEditInput {...register('name')} />
              {errors.name && <span>{errors.name.message}</span>}
            </StyledFormWrapper>

            <StyledFormWrapper>
              <label>EMAIL</label>
              <StyledEditInput {...register('email')} />
              {errors.email && <span>{errors.email.message}</span>}
            </StyledFormWrapper>

            <StyledFormWrapper>
              <label>Cargo</label>
              <StyledEditSelect id="cargo" {...register('is_admin')}>
                <option value="true">Administrador</option>
                <option value="False">Utilizador</option>
              </StyledEditSelect>
              {errors.is_admin && <span>{errors.is_admin.message}</span>}
            </StyledFormWrapper>

            <StyledFormWrapper>
              <label>Ativo</label>
              <StyledEditSelect id="ativo" {...register('is_active')}>
                <option value="true">Sim</option>
                <option value="False">Não</option>
              </StyledEditSelect>
              {errors.is_active && <span>{errors.is_active.message}</span>}
            </StyledFormWrapper>
          </ProfileForm>
          {!loadingUser && <ListBtn type="submit">SALVAR ALTERAÇÕES</ListBtn>}
          {loadingUser && (
            <ListBtn type="submit">
              <Spinner marginTop="0" stroke="white" />
            </ListBtn>
          )}
        </form>

        <PassTitule>
          <span>EDITAR SENHA</span>
        </PassTitule>

        <form onSubmit={updatePassword}>
          <ProfileForm>
            <StyledFormWrapper>
              <label>SENHA ATUAL</label>
              <StyledEditInput
                type="password"
                {...register2('current_password')}
              />
              {pass_errors.current_password && (
                <span>{pass_errors.current_password.message}</span>
              )}
            </StyledFormWrapper>

            <StyledFormWrapper>
              <label>NOVA SENHA</label>
              <StyledEditInput type="password" {...register2('password')} />
              {pass_errors.password && (
                <span>{pass_errors.password.message}</span>
              )}
            </StyledFormWrapper>

            <StyledFormWrapper>
              <label>CONFIRMAR NOVA SENHA</label>
              <StyledEditInput
                type="password"
                {...register2('confirm_password')}
              />
              {pass_errors.confirm_password && (
                <span>{pass_errors.confirm_password.message}</span>
              )}
            </StyledFormWrapper>
          </ProfileForm>

          {!loadingPass && <ListBtn type="submit">DEFINIR NOVA SENHA</ListBtn>}
          {loadingPass && (
            <ListBtn type="submit">
              <Spinner marginTop="0" stroke="white" />
            </ListBtn>
          )}
        </form>
      </Container>
    </>
  )
}
