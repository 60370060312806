import { ToastContainer } from 'react-toastify'

interface IProps {
  children: React.ReactNode
}

export function ToastProvider({ children }: IProps) {
  return (
    <>
      <ToastContainer />
      {children}
    </>
  )
}
