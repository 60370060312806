import styled, { css } from 'styled-components'

const buttonBaseHover = () => css`
  background: var(--shape);
  border: 2px solid var(--red);
  color: var(--red);
`

const buttonBase = () => css`
  width: 186px;
  height: 43px;

  border-radius: 50px;
  background: var(--red);
  border: 2px solid var(--shape);

  color: var(--shape);

  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  margin: 0.3rem 0 1rem 12rem;
  gap: 1rem;

  button {
    ${buttonBase()}

    &:hover {
      ${buttonBaseHover()}
    }

    & + button {
      ${buttonBase()}

      &:hover {
        ${buttonBaseHover()}
      }
    }
  }
`
