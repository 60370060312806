import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 1.5rem 0.5rem 0.5rem;
  position: relative;
  margin: 0 1rem;

  border-radius: 16px;
  border: 3px solid #ff4c66;
`

export const Label = styled.label`
  position: absolute;
  left: 16px;
  top: -10px;
  color: #ff4c66;
  border: none;
  font-size: 14px;
  font-weight: 600;
  background: #ffff;
  padding: 0 16px 0 5px;
`

export const Body = styled.div`
  gap: 0.5rem;
  display: flex;
  width: 100%;
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 16px;
  border: 3px solid #ff4c66;
  position: relative;
`

export const Operator = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background: #ffff;
  border-radius: 16px;
`

export const Input = styled.input`
  height: 50px;
  width: 60%;
  border: 3px solid var(--tableGrey);
  border-radius: 8px;
  display: flex;
  align-items: center;
  opacity: 0.5;
  padding: 8px;
`

export const ContentButton = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 0.5rem;
  margin-top: 0.5rem;
  padding: 0;

  .clean {
    background: white;
    color: red;
    border: 1px solid;
  }
`

export const Button = styled.button`
  border-radius: 8px;
  width: 100%;
  background: #ff4c66;
  color: #ffff;
  height: 60px;
  border: none;
  font-size: 20px;
  transition: opacity 0.3s ease;

  :hover {
    opacity: 0.5;
  }
`
