import {
  FormContainer,
  StyledFormWrapper,
  StyledButton,
  Content,
  Contents,
  Container,
  StyledInputPassword,
} from './styles'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import logo from '../assets/svg/logoCA.svg'
import eye from '../assets/svg/eye.svg'
import hidden from '../assets/svg/hidden.svg'
import { useState } from 'react'
import { api } from '../../services/api'
import { useParams } from 'react-router-dom'

type FormData = {
  id: string
  password: string
  confirm_password: string
}

const reporPasswordFormSchema = yup.object().shape({
  password: yup.string().required('Password é obrigatória'),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords não são iguais'),
})

export function Repor() {
  const { id } = useParams()

  const [passwordShown, setPasswordShown] = useState(false)

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown)
  }
  const { register, handleSubmit } = useForm<FormData>({
    resolver: yupResolver(reporPasswordFormSchema),
  })

  const reporPassword = handleSubmit((data) => {
    api
      .post(`/users/reset-password/${id}`, data)
      .then()
      .catch(() => console.error('DEU ERRADO'))
  })

  return (
    <>
      <Container>
        <a href="/">
          <img src={logo} alt="logo" />
        </a>
      </Container>
      <Contents>
        <FormContainer>
          <Content>
            <StyledFormWrapper onSubmit={reporPassword}>
              <h2>Reposição de Senha</h2>

              <h3>Insira uma nova password abaixo</h3>

              <input type="hidden" value={id} {...register('id')} />
              <label>NOVA PASSWORD</label>
              <StyledInputPassword>
                <input
                  type={passwordShown ? 'text' : 'password'}
                  placeholder="Password"
                  {...register('password')}
                />
                <img
                  onClick={togglePasswordVisibility}
                  src={passwordShown ? eye : hidden}
                  alt={passwordShown ? 'Exibir senha' : 'Esconder senha'}
                />
              </StyledInputPassword>
              <label>CONFIRME A PASSWORD</label>
              <StyledInputPassword>
                <input
                  type={passwordShown ? 'text' : 'password'}
                  placeholder="Password"
                  {...register('confirm_password')}
                />
                <img
                  onClick={togglePasswordVisibility}
                  src={passwordShown ? eye : hidden}
                  alt={passwordShown ? 'Exibir senha' : 'Esconder senha'}
                />
              </StyledInputPassword>

              <StyledButton>Repor</StyledButton>
            </StyledFormWrapper>
          </Content>
        </FormContainer>
      </Contents>
    </>
  )
}
