import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { GlobalStyle } from './styles/global'
import { ToastProvider } from './providers/toast-provider'
import 'react-toastify/dist/ReactToastify.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <div>
    <GlobalStyle />
    <ToastProvider>
      <App />
    </ToastProvider>
  </div>,
)

reportWebVitals()
