import { HeaderContainer } from './styles'

import logo from '../assets/svg/logoCA.svg'

export function Header() {
  return (
    <HeaderContainer>
      <img src={logo} />
      <p>
        SIMULADOR DE <strong>POUPANÇA FISCAL</strong>
      </p>
      <p></p>
    </HeaderContainer>
  )
}
