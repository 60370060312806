import styled from 'styled-components'

export const Container = styled.div`
  padding-top: 15vh;
  padding-left: 18vw;
  padding-right: 2vw;
  height: 100vh;
  overflow: hidden;
`

export const EmpresasHeader = styled.div`
  padding-right: 2vw;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2rem;
  gap: 2rem;

  span {
    font-size: 24px;
    color: var(--red);
  }
`

export const EditBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;

  img {
    margin-right: 0.5rem;
  }

  button {
    width: 186px;
    height: 43px;

    border-radius: 50px;
    background: var(--shape);
    border: 2px solid var(--red);

    color: var(--red);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  button:hover {
    background: var(--red);
    border: 2px solid var(--shape);
    color: var(--shape);

    img {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(346deg)
        brightness(104%) contrast(102%);
    }
  }

  a {
    text-decoration: none;
  }
`

export const DetailTitule = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
  margin-left: 3rem;

  img {
    width: 95px;
    height: 95px;
    margin-right: 3rem;
  }

  span {
    font-size: 24px;
  }
`

export const DetailsBody = styled.div`
  border-collapse: collapse;
  width: 100%;
  margin-left: 12rem;
  margin-bottom: 2rem;

  th {
    border: none;
    text-align: left;
    height: 40.92px;
    width: 20%;

    color: var(--red);
    font-size: 16px;
  }

  td {
    height: 60.92px;
    border: none;
    text-align: left;

    font-size: 16px;
    font-weight: bold;
  }
`

export const SimulacoesHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  span {
    font-size: 24px;
    color: var(--red);
  }
`

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border: 1px solid var(--tableGrey);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  th {
    border: none;
    text-align: left;
    padding: 4px;
    height: 60.92px;

    background: var(--tableGrey);
    color: var(--shape);
    font-size: 16px;
  }

  td {
    height: 60.92px;
    border: none;
    text-align: left;
    padding: 4px;

    font-size: 14px;
  }

  tr:nth-child(even) {
    background-color: var(--shape);
  }
  tr:nth-child(odd) {
    background-color: var(--tableGrey);
  }
`
