import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { SideBar } from './../../../components/sideBar'
import {
  Container,
  DetailTitule,
  EmpresasHeader,
  StyledEditInput,
  StyledFormWrapper,
} from './styles'

import { Header } from './../../../components/header'
import bank from './../../../components/assets/svg/bank_black.svg'
import { api, mountHeaders } from './../../../services/api'
import { Spinner } from './../../../components/Spinner'
import { ButtonWithBack } from '../../../components/ButtonWithBack'
import { validationToken } from '../../../utils/validateToken'

type FormData = {
  id: null | undefined
  nome: string
  nif: number
  contacto: number
  email: string
}

type Empresa = {
  id: null | undefined
  nome: string
  nif: number
  contacto: number
  email: string
  poupanca_fiscal: number
  n_colaboradores: number
}

const phoneRegExp = /^(9[1236][0-9]) ?([0-9]{3}) ?([0-9]{3})/
const nif = /^([2,5][0-9]{2}) ?([0-9]{3}) ?([0-9]{3})/

const updateEmpresaFormSchema = yup.object().shape({
  nome: yup.string().required('Nome obrigatório'),
  // .matches(/^[aA-zZ\s]+$/, 'Isso não é um nome valido'),
  nif: yup
    .string()
    .required('NIF obrigatório')
    .matches(nif, 'Formato invalido'),
  contacto: yup
    .string()
    .required('Contacto obrigatório')
    .matches(phoneRegExp, 'Formato invalido'),
  email: yup
    .string()
    .required('Email obrigatório')
    .email('Formato invalido (exemplo@exemplo.com)'),
})

export function EditEmpresa() {
  const { id } = useParams()
  const [empresa, setEmpresa] = useState<Empresa>()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    resolver: yupResolver(updateEmpresaFormSchema),
  })

  const getDadosTotals = useCallback(() => {
    api
      .get(`/entidades/${id}`, mountHeaders())
      .then((response) => {
        setEmpresa(response.data)
        reset(response.data)
      })
      .catch((error) => {
        validationToken(error, navigate)
      })
  }, [id, navigate, reset])

  useEffect(() => {
    getDadosTotals()
  }, [getDadosTotals])

  const handleGoBack = () => navigate(`/empresas`)

  const editEmpresa = handleSubmit((data) => {
    setLoading(true)
    api
      .put(`/entidades/${id}`, data, mountHeaders())
      .then(() => {
        setLoading(false)
        reset()
        handleGoBack()
      })
      .catch((error) => {
        console.error('Error: ', error)
      })
  })

  return (
    <>
      <Header />
      <SideBar />
      <Container>
        <EmpresasHeader>
          <span>DADOS DA EMPRESA</span>
        </EmpresasHeader>

        <DetailTitule>
          <img src={bank} alt="Ícone bancário" />

          <span>{empresa?.nome}</span>
        </DetailTitule>

        <StyledFormWrapper onSubmit={editEmpresa}>
          <label>NOME</label>
          <StyledEditInput type="nome" {...register('nome')} />
          {errors.nome && <span>{errors.nome.message}</span>}

          <label>NIF</label>
          <StyledEditInput type="number" {...register('nif')} />
          {errors.nif && <span>{errors.nif.message}</span>}

          <label>EMAIL</label>
          <StyledEditInput type="nome" {...register('email')} />
          {errors.email && <span>{errors.email.message}</span>}

          <label>CONTACTO</label>
          <StyledEditInput type="number" {...register('contacto')} />
          {errors.contacto && <span>{errors.contacto.message}</span>}

          {!loading && <ButtonWithBack handleGoBack={handleGoBack} />}
          {loading && <Spinner stroke="red" />}
        </StyledFormWrapper>
      </Container>
    </>
  )
}
