import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background: #ffffff;
  border: 3px solid var(--tableGrey);
  opacity: 0.5;
  border-radius: 8px;
  padding: 8px;
  margin: 1rem;
  height: 50px;
  font-weight: 500;

  select {
    cursor: pointer;
    margin-left: 0.5rem;
    height: 100%;
    width: 100%;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: none;
    font-size: 20px;
    font-weight: 500;

    &:focus {
      padding: 0 8px;
      outline: none;
      border: none;
    }
  }
`
