import { FormEvent, useCallback, useEffect, useState } from 'react'

import { Header } from '../../../components/header'
import { SideBar } from '../../../components/sideBar'
import { Spinner } from '../../../components/Spinner'
import { useNavigate, useParams, Link } from 'react-router-dom'

import { api, mountHeaders } from '../../../services/api'

import { Container, EmpresasHeader, Table } from './styles'
import Pagination from '../../../components/Pagination'
import { ISimulation } from '../interfaces'
import { NotFoundData } from '../../../components/NotFoundData'
import { formattingCurrency, formatDate } from '../../../utils/formatting'
import view from '../../../components/assets/svg/carbon_view.svg'
import deleteIcon from '../../../components/assets/svg/delete.svg'
import { validationToken } from '../../../utils/validateToken'
import { SearchBody } from '../../../components/SearchBar/styles'
import search from '../../../components/assets/svg/search.svg'
import { Toast } from '../../../components/Toast'
import { useDebounce } from '../../../hooks/useDebounce'

export function SimulationsByCompany() {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const [simulations, setSimulations] = useState<ISimulation[]>([])
  const LIMIT = 6
  const [offset, setOffset] = useState(0)
  const [count, setCount] = useState(0)
  const [text, setText] = useState('')

  const fetchSimulations = useCallback(
    async (search = '') => {
      try {
        setIsLoading(true)
        const searchName = search ? `&search=${search}` : ''
        const url = `/simulation/by-entity-id/${id}?page=${offset}&perPage=${LIMIT}${searchName}`
        const { data } = await api.get<{
          items: ISimulation[]
          totalItems: number
          message: string
        }>(url, mountHeaders())

        if (data?.message) {
          Toast({ type: 'error', message: data.message })
          setIsLoading(false)
          validationToken(data?.message, navigate)
          return
        }
        setSimulations(data?.items || [])
        setCount(data?.totalItems || 0)
        setIsLoading(false)
      } catch (error) {
        console.log('error: ', error)
      }
    },
    [id, navigate, offset],
  )

  useEffect(() => {
    const fetchData = async () => await fetchSimulations()
    fetchData()
  }, [fetchSimulations])

  const handleDeleteSimulation = async (id: string) => {
    setIsLoading(true)
    try {
      await api.delete(`simulation/${id}`, mountHeaders())
      navigate('/empresas')
    } catch (error) {
      console.error('error: ', error)
      setIsLoading(false)
    }
  }

  const saveAppName = useDebounce(
    useCallback(async (e: string) => {
      await fetchSimulations(e)
    }, []),
    800,
  )

  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    setText(event.currentTarget.value)
    saveAppName(event.currentTarget.value)
  }

  return (
    <>
      <Header />
      <SideBar />
      <Container>
        <EmpresasHeader>
          <span>LISTAGEM DE SIMULAÇÕES</span>

          <SearchBody>
            <img src={search} alt="Ícone de busca" />
            <input
              placeholder="Pesquisa"
              value={text}
              onChange={handleChange}
            />
          </SearchBody>
        </EmpresasHeader>

        <Table>
          <tr>
            <th>Nº Pedido</th>
            <th>Empresa</th>
            <th>Data de Simulação</th>
            <th>Nº Colaboradores</th>
            <th>Poupança Final</th>
            <th>Ações</th>
          </tr>

          {!isLoading &&
            simulations?.length > 0 &&
            simulations?.map((simulation) => {
              return (
                <tr key={simulation.id}>
                  <td>{simulation.numb}</td>
                  <td>{simulation.nome}</td>
                  <td>{formatDate(simulation.created_at)}</td>
                  <td>{simulation.n_colaboradores}</td>
                  <td>{formattingCurrency(simulation.poupanca_fiscal)}</td>
                  <td
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1rem',
                    }}
                  >
                    <Link to={`/empresas/${id}/simulations/${simulation.id}`}>
                      <img src={view} alt="Ícone de detalhes" />
                    </Link>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleDeleteSimulation(simulation.id)}
                    >
                      <img src={deleteIcon} alt="Ícone de delete" />
                    </span>
                  </td>
                </tr>
              )
            })}
        </Table>

        {isLoading && <Spinner stroke="red" />}

        {simulations?.length === 0 && !isLoading && <NotFoundData />}

        {simulations?.length > 0 && (
          <Pagination
            limit={LIMIT}
            total={count}
            offset={offset}
            setOffset={setOffset}
          />
        )}
      </Container>
    </>
  )
}
