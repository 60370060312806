import { format } from 'date-fns'
import { TypeTerms, TypeTermsAPI } from '../pages/companies/interfaces'

export const formatDate = (date: string | Date, formatDate = 'dd/MM/yyyy') => {
  if (!date) return
  return format(date, formatDate)
}

export const formattingCurrency = (dataCurrency: number) => {
  if (!dataCurrency && dataCurrency !== 0) return
  const formato = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  return formato.format(dataCurrency)
}

export const formatContractType = (type: TypeTerms) =>
  ({
    with_term: 'Com Termo',
    without_term: 'Sem Termo',
    uncertain_term: 'Termo Incerto',
  })[type]

export const formatContractTypeToAPI = (type: TypeTermsAPI) =>
  ({
    'Com Termo': 'with_term',
    'Sem Termo': 'without_term',
    'Termo Incerto': 'uncertain_term',
  })[type]
