import { ButtonHTMLAttributes } from 'react'
import { Container } from './styles'

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  handleGoBack: () => void
}

export const ButtonWithBack = ({ handleGoBack }: IProps) => {
  return (
    <Container>
      <button onClick={handleGoBack}>VOLTAR</button>
      <button type="submit">EDITAR</button>
    </Container>
  )
}
