import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const SideBarContainer = styled.div`
  position: fixed;
  width: 210px;
  height: 90vh;

  background: var(--darkGrey);

  color: var(--shape);
  z-index: 4;

  margin-top: 10vh;
`

export const SideBarLink = styled(NavLink)`
  text-decoration: none;
  color: var(--shape);
  cursor: pointer;

  display: flex;
  align-items: center;

  width: 100%;
  height: 5%;

  padding: 2rem 2rem 2rem 1rem;

  font-size: 18px;

  border-bottom: 0.5px solid var(--shape);

  img {
    margin-top: -0.5rem;
    margin-right: 0.5rem;
    width: 24px;
    height: 24px;
  }
  :hover {
    background: var(--disabled);
  }

  &.active {
    background: var(--disabled);
  }
`

export const SideBarDropDown = styled.div`
  text-decoration: none;
  color: var(--shape);
  cursor: pointer;

  align-items: center;

  width: 100%;
  height: 5%;

  padding: 2rem 2rem 2rem 1rem;

  font-size: 18px;

  border-bottom: 0.5px solid var(--shape);
  border-top: none;
  border-left: none;
  border-right: none;

  img {
    margin-top: -0.2rem;
    margin-right: 0.5rem;
    width: 24px;
    height: 24px;
  }
  :hover {
    background: var(--disabled);
  }
`

export const DropdownTitle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: space-between;
  justify-content: left;
  margin-top: -9%;
  img {
    :last-child {
      width: 12px;
      height: 12px;
      margin-top: 5px;
      margin-left: 20px;
      z-index: 99;
    }
    a {
      margin-bottom: 100%;
      z-index: 99;
    }
  }
`

export const ContentSideBarLinkDrop = styled.div`
  display: flex;
  flex-direction: column;
`

export const SideBarLinkDrop = styled(NavLink)`
  text-decoration: none;
  color: var(--shape);
  cursor: pointer;

  display: flex;
  align-items: center;

  width: 100%;
  height: 70px;
  margin-left: 0px;
  padding-left: 4rem;
  font-size: 18px;

  border-bottom: 0.5px solid #ffff;

  img {
    margin-right: 0.5rem;
    width: 24px;
    height: 24px;
  }
  :hover {
    background: var(--disabled);
  }

  &.active {
    background: var(--disabled);
  }
`

export const SideBarLinkLogOut = styled.a`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100%;

  text-decoration: none;
  color: var(--shape);
  font-size: 18px;

  div {
    border-top: 0.5px solid var(--shape);
    padding: 1rem 2rem 9rem 1rem;
    display: flex;
    cursor: pointer;
    width: 100%;

    :hover {
      background: var(--disabled);
    }

    img {
      margin-right: 0.5rem;
      width: 24px;
      height: 24px;
    }
  }
`
