import styled from 'styled-components'

export const HeaderContainer = styled.div`
  width: 100vw;
  height: 10vh;
  z-index: 5;
  position: fixed;

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: var(--shape);

  background: var(--disabled);

  padding: 1rem 0.5rem 0.5rem 5rem;

  font-size: 32px;

  img {
    height: 64px;
    width: 93px;
  }
`
