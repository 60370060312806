import { Container } from './styles'
import download from '../../assets/svg/download.svg'
import { ButtonHTMLAttributes } from 'react'

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  handleExport: () => void
  labelText: string
}

export const ExportButton = ({ handleExport, labelText, ...props }: IProps) => {
  return (
    <Container>
      <button onClick={handleExport} {...props}>
        <img src={download} alt="Ícone de download" />
        {labelText}
      </button>
    </Container>
  )
}
