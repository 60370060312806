import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useApi } from '../../services/api'

import {
  ContentSideBarLinkDrop,
  DropdownTitle,
  SideBarContainer,
  SideBarDropDown,
  SideBarLink,
  SideBarLinkDrop,
  SideBarLinkLogOut,
} from './styles'

import bank from '../assets/svg/bank.svg'
import settings from '../assets/svg/setting.svg'
import logOut from '../assets/svg/log-out.svg'
import Down from '../assets/svg/down.svg'
import users from '../assets/svg/user_white.svg'

export function SideBar() {
  const localUser = localStorage.getItem('utilizador')

  const getUser = (user: string | null) => {
    try {
      if (user) return JSON.parse(user)
    } catch (err) {
      console.error('err: ', err)
      return { is_admin: false }
    }
  }
  const user = getUser(localUser)

  const navigate = useNavigate()
  const { logout } = useApi()

  const handleLogOut = async () => {
    navigate('/login')
    logout()
  }

  const [openDropdown, setOpenDropdown] = useState(false)

  return (
    <SideBarContainer>
      <SideBarLink to="/empresas">
        <img src={bank} alt="Ícone para empresas" />
        <p>Empresas</p>
      </SideBarLink>

      {user.is_admin && (
        <SideBarDropDown>
          <DropdownTitle onClick={() => setOpenDropdown((state) => !state)}>
            <img src={settings} alt="Configurações" />
            <p>Configurações</p>
            <img id="arrow" src={Down} alt="Seta para cima" />
          </DropdownTitle>
        </SideBarDropDown>
      )}
      <>
        {openDropdown && (
          <ContentSideBarLinkDrop>
            <SideBarLinkDrop to="/configurations/users">
              <img src={users} alt="Usuários" />
              <p>Utilizadores</p>
            </SideBarLinkDrop>
            <SideBarLinkDrop to="/configurations/variables">
              <img src={users} alt="Variáveis" />
              <p>Variáveis</p>
            </SideBarLinkDrop>
          </ContentSideBarLinkDrop>
        )}
      </>

      <SideBarLinkLogOut onClick={handleLogOut}>
        <div>
          <img src={logOut} alt="Sair" />
          <p>Sair</p>
        </div>
      </SideBarLinkLogOut>
    </SideBarContainer>
  )
}
