import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { differenceInMonths } from 'date-fns'

import { Header } from '../../../../components/header'
import { SideBar } from '../../../../components/sideBar'
import { Spinner } from '../../../../components/Spinner'

import { api, mountHeaders } from '../../../../services/api'

import { Container, EmpresasHeader, Table, Subtitle, Footer } from './styles'

import Pagination from '../../../../components/Pagination'
import { NotFoundData } from '../../../../components/NotFoundData'
import { ExportButton } from '../../../../components/Button/export'

import edit from '../../../../components/assets/svg/edit.svg'
import arrowLeft from '../../../../components/assets/svg/arrow_left.svg'

import { IEmployee, ISimulation } from '../../interfaces'

import {
  formatContractType,
  formattingCurrency,
  formatDate,
} from '../../../../utils/formatting'
import { validationToken } from '../../../../utils/validateToken'

export function SimulationDetails() {
  const { id, simulation_id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [simulation, setSimulation] = useState({} as ISimulation)
  const [employees, setEmployees] = useState([] as IEmployee[])
  const navigate = useNavigate()

  const fetchSimulations = useCallback(async () => {
    setIsLoading(true)
    try {
      const url = `/simulation/by-id/${simulation_id}`
      const { data } = await api.get(url, mountHeaders())
      setSimulation(data)
      setEmployees(data?.Employee)
      setIsLoading(false)
    } catch (error) {
      validationToken(error, navigate)
      setIsLoading(false)
    }
  }, [navigate, simulation_id])

  useEffect(() => {
    const fetchData = async () => await fetchSimulations()
    fetchData()
  }, [fetchSimulations])

  const LIMIT = 10
  const [offset, setOffset] = useState(0)

  const handleExport = () => {
    setIsLoading(true)
    api
      .post(
        `/simulation/export/${simulation_id}`,
        { responseType: 'text' },
        mountHeaders(),
      )
      .then(({ data }) => {
        const blob = new Blob([data], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `Simulation_${simulation.numb}.csv`)
        document.body.appendChild(link)
        link.click()
        link.remove()
        setIsLoading(false)
      })
      .catch((error) => {
        console.error('error: ', error)
        setIsLoading(false)
      })
  }

  const handleGoBack = () => navigate(`/empresas/${id}/simulations`)

  const remainingMonthsOfRateReduction = (employee: IEmployee) => {
    const { contract_final_date, contract_initial_date } = employee
    const difference = differenceInMonths(
      contract_final_date,
      contract_initial_date,
    )

    if (employee.terms === 'uncertain_term' && difference > 60) return 60
    return difference
  }

  return (
    <>
      <Header />
      <SideBar />
      <Container>
        <EmpresasHeader>
          <span>
            {`DADOS DA SIMULAÇÃO - ${simulation?.numb || ''} |`}{' '}
            <strong> {`${simulation?.nome || ''}`}</strong>
          </span>
          <ExportButton
            handleExport={handleExport}
            labelText="Exportar Simulação"
          />
        </EmpresasHeader>

        <Table>
          <tr>
            <th>Nº</th>
            <th>Nome</th>
            <th>Data de Nascimento</th>
            <th>Tipo de Contrato</th>
            <th>Data Contrato</th>
            <th>DCI</th>
            <th>Data de Efetividade</th>
            <th>Data Inicial PE</th>
            <th>Data Final RC</th>
            <th>RMB</th>
            <th>MRRT</th>
            <th>Poupança Fiscal</th>
            <th>Ações</th>
          </tr>

          {!isLoading &&
            employees?.length > 0 &&
            employees.map((employee, index) => (
              <tr key={employee.id}>
                <td>{index + 1}</td>
                <td>{employee.nome}</td>
                <td>{formatDate(employee.data_nascimento)}</td>
                <td>{formatContractType(employee.terms)}</td>
                <td>{formatDate(employee.contract_initial_date)}</td>
                <td>{employee.contract_duration}</td>
                <td>{formatDate(employee.data_efetivo)}</td>
                <td>{formatDate(employee.contract_initial_date)}</td>
                <td>{formatDate(employee.contract_final_date)}</td>
                <td>{formattingCurrency(employee.remuneracao_mensal / 100)}</td>
                <td>{remainingMonthsOfRateReduction(employee)}</td>
                <td>{formattingCurrency(employee.tax_benefit)}</td>
                <td
                  style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
                >
                  <Link
                    to={`/empresas/${id}/simulations/${simulation.id}/edit/${employee.id}`}
                  >
                    <img src={edit} alt="Ícone de editar" />
                  </Link>
                </td>
              </tr>
            ))}
        </Table>

        {isLoading && <Spinner stroke="red" />}

        {employees?.length === 0 && !isLoading && <NotFoundData />}

        {employees?.length > 0 && (
          <Pagination
            limit={LIMIT}
            total={10}
            offset={offset}
            setOffset={setOffset}
          />
        )}

        {!isLoading && (
          <>
            <Subtitle>
              <span>
                Legenda: DCI - Duração do contrato Inicial | PE - Produção de
                efeitos | RTC - Redução da taxa contributiva | RNB - Remuneração
                base mensal | MRRT - Meses remanescentes de redução da taxa
                contributiva || EEA - Efetivo em empregos anteriores
              </span>
            </Subtitle>

            <Footer>
              <div onClick={handleGoBack}>
                <img src={arrowLeft} alt="Ícone de seta para esquerda" /> Voltar
              </div>
              <span>
                TOTAL:{' '}
                <strong>
                  {formattingCurrency(
                    employees?.length > 0 ? simulation.poupanca_fiscal : 0,
                  )}
                </strong>{' '}
              </span>
            </Footer>
          </>
        )}
      </Container>
    </>
  )
}
