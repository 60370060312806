import { useContext, useState } from 'react'
import {
  Container,
  FormContainer,
  StyledFormWrapper,
  StyledInput,
  StyledButton,
  Content,
  Contents,
  ImageContainer,
  StyledInputPassword,
} from './styles'
import { AuthContext } from '../../context/Auth/AuthContext'

import logo from '../assets/svg/logoCA.svg'
import eye from '../assets/svg/eye.svg'
import hidden from '../assets/svg/hidden.svg'
import { Spinner } from '../Spinner'
import { useNavigate } from 'react-router'

export function Login() {
  const [passwordShown, setPasswordShown] = useState(false)

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown)
  }

  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const disabled = !email || !password || loading

  const handleLogin = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setLoading(true)
    if (email && password) {
      const data = await auth.signIn(email, password)
      setLoading(false)
      if (data?.token) navigate('/empresas')
    }
  }

  return (
    <Container>
      <ImageContainer>
        <img alt="logo" src={logo} />
      </ImageContainer>
      <Contents>
        <FormContainer>
          <Content>
            <StyledFormWrapper>
              <h2>Faça o seu Login</h2>

              <h3>Insira o seu email e password abaixo</h3>
              <label>EMAIL</label>
              <StyledInput
                type="email"
                placeholder="Inserir Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label>PASSWORD</label>
              <StyledInputPassword>
                <input
                  type={passwordShown ? 'text' : 'password'}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <img
                  onClick={togglePasswordVisibility}
                  src={passwordShown ? eye : hidden}
                  alt="ícone para exibir senha"
                />
              </StyledInputPassword>

              <a href="/recuperar">Esqueceu a password?</a>

              <StyledButton
                type="submit"
                disabled={disabled}
                onClick={handleLogin}
              >
                {!loading ? 'Entrar' : <Spinner marginTop="0" stroke="white" />}
              </StyledButton>
            </StyledFormWrapper>
          </Content>
        </FormContainer>
      </Contents>
    </Container>
  )
}
