import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  img {
    margin-right: 0.5rem;
  }

  button {
    width: 186px;
    height: 43px;
    font-weight: 500;

    border-radius: 50px;
    background: var(--shape);
    border: 2px solid #ff4c66;

    color: #ff4c66;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  button:hover {
    background: var(--red);
    opacity: 0.8;
    color: #ffff;

    img {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(346deg)
        brightness(104%) contrast(102%);
    }
  }
`
