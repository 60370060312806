import { NavigateFunction } from 'react-router'

interface IError {
  response: {
    data: { message: string }
    status: number
  }
}

export const validationToken = (err: unknown, navigate: NavigateFunction) => {
  const error = err as IError
  const hasInvalidToken =
    error?.response?.data?.message?.includes('Invalid Token') ||
    error?.response?.status === 401

  if (hasInvalidToken) {
    localStorage.removeItem('authToken')
    navigate('/login')
  }
}
